<template>
  <div id="specialReview">
    <el-dialog
      :title="specialReviewFormTitle"
      width="1200px"
      :visible.sync="specialReviewDialogVisible"
      :close-on-click-modal="false"
      @close="specialReviewDialogClose"
    >
      <el-form
        ref="specialReviewFormRef"
        :model="specialReviewForm"
        :rules="specialReviewFormRules"
        label-position="right"
        label-width="160px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="specialReviewForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品型号" prop="productSpec">
              <el-input v-model="specialReviewForm.productSpec" placeholder="请输入产品型号" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="过程名称" prop="processName">
              <el-input v-model="specialReviewForm.processName" placeholder="请输入过程名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="过程类别" prop="processType">
              <el-radio-group v-model="specialReviewForm.processType">
                <el-radio :label="1">
                  关键
                </el-radio>
                <el-radio :label="2">
                  特殊
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="确认评审负责人" prop="reviewResponsible">
              <el-input v-model="specialReviewForm.reviewResponsible" placeholder="请输入确认评审负责人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="确认评审类别" prop="reviewType">
              <el-radio-group v-model="specialReviewForm.reviewType">
                <el-radio :label="1">
                  首次确认
                </el-radio>
                <el-radio :label="2">
                  再确认
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="确认评审时间" prop="reviewTime">
              <el-date-picker v-model="specialReviewForm.reviewTime" placeholder="请选择确认评审时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作为关键或特殊过程确认评审的原因" prop="reason">
              <el-input
                v-model="specialReviewForm.reason"
                placeholder="请输入作为关键或特殊过程确认评审的原因"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="关键或特殊过程所使用的主要设备、工装">
              <vxe-toolbar v-if="specialReviewFormTitle !== '特殊过程评审表详情'">
                <template #buttons>
                  <vxe-button @click="insertRowEventDev()">
                    新增
                  </vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="xTableDev"
                border
                resizable
                show-overflow
                keep-source
                :data="specialReviewDevList"
                :edit-config="{trigger: 'click', mode: 'cell'}"
              >
                <vxe-table-column
                  field="devNo"
                  title="序号"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="devName"
                  title="名称"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column v-if="specialReviewFormTitle !== '特殊过程评审表详情'" title="操作" width="100">
                  <template #default="{ row }">
                    <template>
                      <vxe-button @click="removeRowEventDev(row)">
                        删除
                      </vxe-button>
                    </template>
                  </template>
                </vxe-table-column>
              </vxe-table>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="关键或特殊过程所使用的作业指导书">
              <vxe-toolbar v-if="specialReviewFormTitle !== '特殊过程评审表详情'">
                <template #buttons>
                  <vxe-button @click="insertRowEventFile()">
                    新增
                  </vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="xTableFile"
                border
                resizable
                show-overflow
                keep-source
                :data="specialReviewFileList"
                :edit-config="{trigger: 'click', mode: 'cell'}"
              >
                <vxe-table-column
                  field="fileNo"
                  title="文件号"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="fileName"
                  title="文件名称"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column v-if="specialReviewFormTitle !== '特殊过程评审表详情'" title="操作" width="100">
                  <template #default="{ row }">
                    <template>
                      <vxe-button @click="removeRowEventFile(row)">
                        删除
                      </vxe-button>
                    </template>
                  </template>
                </vxe-table-column>
              </vxe-table>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="有无作业指导书" prop="confirmationContent1">
              <el-radio-group v-model="specialReviewForm.confirmationContent1">
                <el-radio :label="1">
                  有
                </el-radio>
                <el-radio :label="0">
                  无
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备、工装是否通过验收、检定" prop="confirmationContent2">
              <el-radio-group v-model="specialReviewForm.confirmationContent2">
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否规定并实施对设备、工装的保养和检查" prop="confirmationContent3">
              <el-radio-group v-model="specialReviewForm.confirmationContent3">
                <el-radio :label="1">
                  有
                </el-radio>
                <el-radio :label="0">
                  无
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="特殊过程的员工是否持证上岗" prop="confirmationContent4">
              <el-radio-group v-model="specialReviewForm.confirmationContent4">
                <el-radio :label="1">
                  有
                </el-radio>
                <el-radio :label="0">
                  无
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="现场观察，小批量试验结果是否合格" prop="confirmationContent5">
              <el-radio-group v-model="specialReviewForm.confirmationContent5">
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否对过程参数进行监视并保持纪录" prop="confirmationContent6">
              <el-radio-group v-model="specialReviewForm.confirmationContent6">
                <el-radio :label="1">
                  有
                </el-radio>
                <el-radio :label="0">
                  无
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="确认评审结论" prop="reviewConclusion">
              <el-radio-group v-model="specialReviewForm.reviewConclusion">
                <el-radio :label="1">
                  可以作为关键或特殊过程管理，确认合格
                </el-radio>
                <el-radio :label="2">
                  无须作为关键或特殊过程管理
                </el-radio>
                <el-radio :label="3">
                  其他
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他" prop="reviewConclusionOther">
              <el-input v-model="specialReviewForm.reviewConclusionOther" placeholder="请输入其他" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="参与确认评审人员">
              <vxe-toolbar v-if="specialReviewFormTitle !== '特殊过程评审表详情'">
                <template #buttons>
                  <vxe-button @click="insertRowEventPenson()">
                    新增
                  </vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="xTablePenson"
                border
                resizable
                show-overflow
                keep-source
                :data="specialReviewPersonList"
                :edit-config="{trigger: 'click', mode: 'cell'}"
              >
                <vxe-table-column
                  field="personDept"
                  title="部门"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="personName"
                  title="姓名"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column
                  field="personDuty"
                  title="职务"
                  :edit-render="{name: '$input', props: {clearable: true}}"
                />
                <vxe-table-column v-if="specialReviewFormTitle !== '特殊过程评审表详情'" title="操作" width="100">
                  <template #default="{ row }">
                    <template>
                      <vxe-button @click="removeRowEventPenson(row)">
                        删除
                      </vxe-button>
                    </template>
                  </template>
                </vxe-table-column>
              </vxe-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="specialReviewDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="specialReviewFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="specialReviewPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="productSpec" label="产品型号" />
      <el-table-column prop="processName" label="过程名称" />
      <el-table-column label="过程类别">
        <template slot-scope="scope">
          <span v-if="scope.row.processType === 1">关键</span>
          <span v-if="scope.row.processType === 2">特殊</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewResponsible" label="确认评审负责人" />
      <el-table-column label="确认评审类别">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewType === 1">首次确认</span>
          <span v-if="scope.row.reviewType === 2">再确认</span>
        </template>
      </el-table-column>
      <el-table-column label="确认评审时间">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewTime">{{ scope.row.reviewTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="作为关键或特殊过程确认评审的原因" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="specialReviewPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addSpecialReview, deleteSpecialReview, updateSpecialReview, selectSpecialReviewInfo, selectSpecialReviewList } from '@/api/technology/specialReview'

export default {
  data () {
    return {
      specialReviewDialogVisible: false,
      specialReviewFormTitle: '',
      specialReviewForm: {
        id: '',
        productName: '',
        productSpec: '',
        processName: '',
        processType: '',
        reviewResponsible: '',
        reviewType: '',
        reviewTime: '',
        reason: '',
        confirmationContent1: '',
        confirmationContent2: '',
        confirmationContent3: '',
        confirmationContent4: '',
        confirmationContent5: '',
        confirmationContent6: '',
        reviewConclusion: '',
        reviewConclusionOther: '',
        specialReviewDevJson: '',
        specialReviewFileJson: '',
        specialReviewPersonJson: ''
      },
      specialReviewFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      specialReviewPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      },
      specialReviewDevList: [],
      specialReviewFileList: [],
      specialReviewPersonList: []
    }
  },
  created () {
    selectSpecialReviewList(this.searchForm).then(res => {
      this.specialReviewPage = res
    })
  },
  methods: {
    specialReviewDialogClose () {
      this.$refs.specialReviewFormRef.resetFields()
      this.specialReviewDevList = []
      this.specialReviewFileList = []
      this.specialReviewPersonList = []
    },
    specialReviewFormSubmit () {
      if (this.specialReviewFormTitle === '特殊过程评审表详情') {
        this.specialReviewDialogVisible = false
        return
      }
      this.$refs.specialReviewFormRef.validate(async valid => {
        if (valid) {
          this.specialReviewForm.specialReviewDevJson = JSON.stringify(this.$refs.xTableDev.getTableData().tableData)
          this.specialReviewForm.specialReviewFileJson = JSON.stringify(this.$refs.xTableFile.getTableData().tableData)
          this.specialReviewForm.specialReviewPersonJson = JSON.stringify(this.$refs.xTablePenson.getTableData().tableData)
          if (this.specialReviewFormTitle === '新增特殊过程评审表') {
            await addSpecialReview(this.specialReviewForm)
          } else if (this.specialReviewFormTitle === '修改特殊过程评审表') {
            await updateSpecialReview(this.specialReviewForm)
          }
          this.specialReviewPage = await selectSpecialReviewList(this.searchForm)
          this.specialReviewDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.specialReviewFormTitle = '新增特殊过程评审表'
      this.specialReviewDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSpecialReview(row.id)
        if (this.specialReviewPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.specialReviewPage = await selectSpecialReviewList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.specialReviewFormTitle = '修改特殊过程评审表'
      this.specialReviewDialogVisible = true
      this.selectSpecialReviewInfoById(row.id)
    },
    handleInfo (index, row) {
      this.specialReviewFormTitle = '特殊过程评审表详情'
      this.specialReviewDialogVisible = true
      this.selectSpecialReviewInfoById(row.id)
    },
    selectSpecialReviewInfoById (id) {
      selectSpecialReviewInfo(id).then(res => {
        this.specialReviewForm.id = res.id
        this.specialReviewForm.productName = res.productName
        this.specialReviewForm.productSpec = res.productSpec
        this.specialReviewForm.processName = res.processName
        this.specialReviewForm.processType = res.processType
        this.specialReviewForm.reviewResponsible = res.reviewResponsible
        this.specialReviewForm.reviewType = res.reviewType
        this.specialReviewForm.reviewTime = res.reviewTime
        this.specialReviewForm.reason = res.reason
        this.specialReviewForm.confirmationContent1 = res.confirmationContent1
        this.specialReviewForm.confirmationContent2 = res.confirmationContent2
        this.specialReviewForm.confirmationContent3 = res.confirmationContent3
        this.specialReviewForm.confirmationContent4 = res.confirmationContent4
        this.specialReviewForm.confirmationContent5 = res.confirmationContent5
        this.specialReviewForm.confirmationContent6 = res.confirmationContent6
        this.specialReviewForm.reviewConclusion = res.reviewConclusion
        this.specialReviewForm.reviewConclusionOther = res.reviewConclusionOther
        this.specialReviewDevList = res.specialReviewDevList
        this.specialReviewFileList = res.specialReviewFileList
        this.specialReviewPersonList = res.specialReviewPersonList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSpecialReviewList(this.searchForm).then(res => {
        this.specialReviewPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSpecialReviewList(this.searchForm).then(res => {
        this.specialReviewPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSpecialReviewList(this.searchForm).then(res => {
        this.specialReviewPage = res
      })
    },
    async insertRowEventDev (row) {
      let { row: newRow } = await this.$refs.xTableDev.insertAt({}, -1)
      await this.$refs.xTableDev.setActiveRow(newRow)
    },
    removeRowEventDev (row) {
      this.$refs.xTableDev.remove(row)
    },
    async insertRowEventFile (row) {
      let { row: newRow } = await this.$refs.xTableFile.insertAt({}, -1)
      await this.$refs.xTableFile.setActiveRow(newRow)
    },
    removeRowEventFile (row) {
      this.$refs.xTableFile.remove(row)
    },
    async insertRowEventPenson (row) {
      let { row: newRow } = await this.$refs.xTablePenson.insertAt({}, -1)
      await this.$refs.xTablePenson.setActiveRow(newRow)
    },
    removeRowEventPenson (row) {
      this.$refs.xTablePenson.remove(row)
    }
  }
}
</script>

<style>
</style>
