import axios from '@/common/axios'
import qs from 'qs'

export function addSpecialReview (data) {
  return axios({
    method: 'post',
    url: '/technology/specialReview/add',
    data: qs.stringify(data)
  })
}

export function deleteSpecialReview (id) {
  return axios({
    method: 'delete',
    url: '/technology/specialReview/delete/' + id
  })
}

export function updateSpecialReview (data) {
  return axios({
    method: 'put',
    url: '/technology/specialReview/update',
    data: qs.stringify(data)
  })
}

export function selectSpecialReviewInfo (id) {
  return axios({
    method: 'get',
    url: '/technology/specialReview/info/' + id
  })
}

export function selectSpecialReviewList (query) {
  return axios({
    method: 'get',
    url: '/technology/specialReview/list',
    params: query
  })
}
